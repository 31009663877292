import * as React from "react"
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Footer from "../components/footer"

const PressPage = ({ data }) => {
    const page = data.press
    return (
        <Layout>
            <HelmetDatoCms 
                seo={page.seoMetaTags}
                bodyAttributes={{
                    class: 'light-bg press-page'
                }}
            />
            <main key="main" class={`press`}>
                <section className="media-content press-media">
                    {page.nodes.map(press => (
                        <div className="media-section">
                            <div className="media-item press-item">
                                <h5>{press.subtitle}</h5>
                                <p className="lede"><strong>
                                    {press.text}
                                </strong></p>
                                <a href={press.url} target="_blank" rel="noreferrer" className="press-item-cta">
                                    <span className="btn-text subnavigation">{press.cta}</span>
                                    <span className="arrow">
                                        <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="12" y="5" width="1" height="12" rx="0.5" transform="rotate(90 12 5)" fill="#DCFA6E"/>
                                            <path className="arrow-head" d="M8 1L12 5.5L8 10" stroke="#DCFA6E" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    ))}
                </section>
            </main>
            <Footer color='blue'></Footer>
        </Layout>
    )
}

export default PressPage

export const query = graphql`
query {
    press: allDatoCmsPressItem {
        nodes {
            text
            url
            subtitle
            cta
        }
    }
}
`